import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ORDER } from '../../../config/constants/common';
import { MARKETING_CLAIMS_DEF_LINK } from '../../../config/links/links';
import { FEATURE_FLAGS, SOCIAL_CUES } from '../../../config/setup/setup';
import { isIESite } from '../../../helpers/pages';
import {
  getBackendSocialCueMessage,
  getRedTextDealSocialCue,
} from '../../../helpers/socialCues';
import ThemeContext from '../../../providers/ThemeProvider';

const DealSocialCueTop = ({
  order = ORDER.odd,
  socialCueClaim = '',
  socialCuesData = {},
  socialCuesAvailability = 0,
  socialCuesBought = 0,
  className,
  containerExtraClasses,
  isMedium = false,
  isXS = false,
}) => {
  const theme = useContext(ThemeContext);
  const even = order === ORDER.even;
  const isIrelandSite = isIESite();
  const MAX_STRING_LENGTH_XS_CARD = 25;

  if (!FEATURE_FLAGS[SOCIAL_CUES]) return null;

  const socialCueMessage = !even && getBackendSocialCueMessage(socialCuesData);
  const socialAnotherCueMessage = getRedTextDealSocialCue({
    deal: {
      socialCueClaim,
    },
  });
  if (!socialCueMessage && !socialAnotherCueMessage) return null;

  const isLongMessage =
    socialCueMessage?.length > MAX_STRING_LENGTH_XS_CARD ||
    socialAnotherCueMessage?.length > MAX_STRING_LENGTH_XS_CARD;

  // xSmall and medium deal card, no room for long message
  if ((isXS || isMedium) && isLongMessage) return null;

  const showCueMessageTwo =
    !socialCueMessage &&
    socialAnotherCueMessage &&
    socialAnotherCueMessage.length;
  // && even; will appear less often, so lose this

  return (
    <>
      <div
        className={classNames(
          'social-cues-container__main-image__top',
          containerExtraClasses,
        )}
      >
        {socialCueMessage ? (
          <div className="social-cue__item">{socialCueMessage}</div>
        ) : null}

        {showCueMessageTwo ? (
          <div
            className={classNames('social-cue__item availability', className)}
            onClick={
              isIrelandSite
                ? undefined
                : () => {
                    window.open(MARKETING_CLAIMS_DEF_LINK, '_blank');
                  }
            }
            onKeyDown={(e) => {
              if ((e.key === 'Enter' || e.key === ' ') && !isIrelandSite) {
                window.open(MARKETING_CLAIMS_DEF_LINK, '_blank');
              }
            }}
            role="button"
            style={{
              cursor: !isIrelandSite ? 'pointer' : 'default',
            }}
            tabIndex={0}
          >
            <div className="social-text">{socialAnotherCueMessage}</div>
            {!isIrelandSite && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(MARKETING_CLAIMS_DEF_LINK, '_blank');
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.stopPropagation();
                    window.open(MARKETING_CLAIMS_DEF_LINK, '_blank');
                  }
                }}
                role="button"
                size="sm"
                style={{
                  cursor: 'pointer',
                  marginLeft: '0.2rem',
                }}
                tabIndex={0}
              />
            )}
          </div>
        ) : null}
      </div>
      <style jsx>{`
        .social-cues-container__main-image__top {
          width: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          top: 0;
          left: 0;
          z-index: 999;
          pointer-events: none;
        }
        .social-cues-container__main-image__top.margin-top {
          top: 30px;
        }
        .social-cues-container__main-image__top .social-cue__item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px auto;
          text-align: center;
          background-color: ${theme.colors.socialcuecolor.brandcolor};
          color: ${theme.colors.socialcuecolor.textonbrandcolor};
          font-weight: bold;
          border-radius: 20px;
          pointer-events: auto;
          padding: 2px 10px;
          height: 24px;
          font-size: ${isMedium || isXS ? '13px' : '14px'};
        }
        .social-cues-container__main-image__top
          .social-cue__item.position-left {
          margin: 10px;
        }

        .social-cues-container__main-image__top .social-cue__item.availability {
          background-color: ${theme.colors.socialcuecolor.primary};
          color: ${theme.colors.socialcuecolor.textonprimary};
        }
      `}</style>
    </>
  );
};

DealSocialCueTop.propTypes = {
  className: PropTypes.string,
  containerExtraClasses: PropTypes.string,
  isMedium: PropTypes.bool,
  isXS: PropTypes.bool,
  order: PropTypes.oneOf([ORDER.odd, ORDER.even]),
  socialCueClaim: PropTypes.string,
  socialCuesAvailability: PropTypes.number,
  socialCuesBought: PropTypes.number,
  socialCuesData: PropTypes.object,
};

export default DealSocialCueTop;
